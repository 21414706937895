const size = {
    xs: 320,
    s: 480,
    m: 768,
    l: 992
}

export const device = {
    xs: `only screen and (min-width: ${size.xs}px)`,
    s: `only screen and (min-width: ${size.s}px)`,
    m: `only screen and (min-width: ${size.m}px)`,
    l: `only screen and (min-width: ${size.l}px)`
}

export const palette = {
    light: {
        background: '#FFFCF9',
        backgroundHighlight: '#EEEBE8',
        highlight: '#F95400',
        primary: '#403B37',
        secondary: '#71665B',
        tertiary: '#6F645A'
    }
}