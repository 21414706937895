import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { AiOutlineTwitter, AiFillLinkedin, AiFillInfoCircle, AiFillRead, AiFillInstagram } from "react-icons/ai";

import { device, palette } from "../utils/constants"

const Header = styled.section`
    border-bottom: 1px solid ${palette.light.primary};
    padding: 0.75rem 0;
    @media ${device.l} {
        padding: 1rem 0;
    }
`

const InnerHeader = styled.div`
    width: 100%;
    height: 24px;
`

const Logo = styled.h1`
    float: left;
    font-size: 24px;
    line-height: 24px;
    color: ${palette.light.primary};
    margin-bottom: 0;
    font-family: "Zilla Slab";
`

const Social = styled.div`
    float: right;
    margin-right: -0.5rem;
    margin-top: 2px;
`

const SocialLink = styled.a`
    text-decoration: none;
    color: ${palette.light.primary};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: 0.2s ease all;
    :hover {
        color: ${palette.light.highlight};
        transition: 0.2s ease all;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${palette.light.primary};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: 0.2s ease all;
    :hover {
        color: ${palette.light.highlight};
        transition: 0.2s ease all;
    }
`

const Dot = styled.span`
    color: ${palette.light.highlight};
`

class Head extends Component {
    render = () => {
        return (
            <Header>
                <InnerHeader>
                    <Link to="/"><Logo>sw<Dot>.</Dot></Logo></Link>
                    <Social>
                        <StyledLink to="/"><AiFillRead/></StyledLink>
                        <StyledLink to="/about"><AiFillInfoCircle/></StyledLink>
                        <SocialLink href="https://www.instagram.com/steve.paints.minis/"><AiFillInstagram/></SocialLink>
                        <SocialLink href="https://www.twitter.com/ste_wr"><AiOutlineTwitter/></SocialLink>
                        <SocialLink href="https://www.linkedin.com/in/stephen-wright-640172b8/"><AiFillLinkedin/></SocialLink>
                    </Social>
                </InnerHeader>
            </Header>
        )
    }
}

export default Head