import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import styled from "styled-components"

const Headline = styled.h1`
    font-size: 48px;
`

const SubHeadline = styled.h2`
    font-size: 36px;
    line-height: 149%;
`

const HeadDescriptor = styled.h3`
    font-size: 24px;
    line-height: 149%;
`

const MainContent = styled.div`
    font-size: 18px;
    line-height: 149%;
    margin: 24px 0px;
`

const About = ({data}) => {
    return (
        <Layout>
            <title>{data.site.siteMetadata.title} | About</title>
            <Headline>Hello There 👋🏻.  I'm Steve.</Headline>
            <SubHeadline>A Senior Cloud & DevOps Engineer, Technical Project Manager & Developer</SubHeadline>
            <HeadDescriptor>I'm good at seeing the big picture, helping others ship better code at a faster pace and love automating the 👿 out of everything.</HeadDescriptor>
            <MainContent>I'm also a VR and 🎮 enthusiast, a keen modelmaker 🎨, a lover of Sci-Fi 🖖🏻, a generalist nerd 🤓, and an over-user of the <a href="https://en.wikipedia.org/wiki/Serial_comma">Oxford comma</a>.</MainContent>
            <MainContent>This is my personal blog so all the content is my own opinion (and likely a mish-mash of technical content, hobby content, and anything else that catches my fancy) 👍🏻.</MainContent>
        </Layout>
    )
}

export default About

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`