import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import {useStaticQuery, graphql } from "gatsby"

import { palette, device } from "../utils/constants"

import Header from "./header"

const GlobalStyle = createGlobalStyle`
    html, body {
        overflow-y: auto;
        margin: 0;
        padding: 0 1rem;
        background-color: ${palette.light.background};
        color: ${palette.light.primary};
        @media ${device.l} {
            font-size: 21px;
        }
    }
`

const InnerContent = styled.div`
    padding-top: 1rem;
    @media ${device.l} {
        max-width: 960px;
        margin: 0px auto;
    }
`


const Layout = ({ children }) => {
    const data = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
        `
    )
    return (
        <React.Fragment>
            <GlobalStyle/>
            <Header headerText={data.site.siteMetadata.title}/>
            <InnerContent>
                {children}
            </InnerContent>
        </React.Fragment>
    )
}

export default Layout